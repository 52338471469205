<template>
  <!-- Brands Table -->
  <div class="w-full">
    <!-- Table Header -->
    <div class="brand-grid-template w-full bg-neutral-10 rounded-lg" :class="{'library': !discovery}">
      <BaseText type="body" size="sm" class="text-text-normal pl-12 py-2 pr-4">
        {{ brandTotal.toLocaleString() }} Brands
      </BaseText>
      <BaseText type="body" size="sm" class="text-text-normal px-3 py-2">
        Platform
      </BaseText>
      <BaseText type="body" size="sm" class="text-text-normal px-3 py-2">
        Industry
      </BaseText>
      <BaseText v-if="discovery" type="body" size="sm" class="text-text-normal px-3 py-2">
        {{ discovery ? '# Community Ads' : '# Ads Saved' }}
      </BaseText>
    </div>
    <!-- Loading state -->
    <div v-if="loadingBrands" class="w-full flex justify-center mt-24">
      <BaseLoadingLogo />
    </div>
    <!-- No favorited brands state -->
    <div v-else-if="favoriteFilter && !favoritedBrands.length" class="w-full flex flex-col items-center pt-24">
      <NoBrandFavoritesAnimatedIcon :width="48" :height="48" />
      <BaseText type="label" size="md" class="text-text-muted mt-5">
        Still waiting for your top picks!
      </BaseText> 
      <BaseText type="body" size="sm" class="text-text-muted mt-2 text-center">
        Looks like your heart is still up for grabs! Start {{ discovery ? 'browsing' : 'saving' }} and
        <br/>show some love to those top brands.
      </BaseText>
    </div>
    <!-- No brand results state -->
    <div v-else-if="!brands.length" class="w-full flex flex-col items-center pt-24">
      <NoBrandsAnimatedIcon :width="96" :height="96" />
      <BaseText type="label" size="md" class="relative bottom-4 text-text-muted mt-5">
        No results found!
      </BaseText> 
      <BaseText type="body" size="sm" class="relative bottom-4 text-text-muted mt-2 text-center">
        We couldn't find any matches. Try tweaking your search or
        <br/>{{ discovery ? 'explore the community ads' : 'start saving ads' }} to see results.
      </BaseText>
    </div>
    <!-- Brands Table -->
    <div v-else class="w-full flex flex-col gap-1 mt-1">
      <router-link v-for="(brand, index) in brands" :key="index" 
      class="brand-grid-template rounded-lg transition-colors hover:bg-neutral-25" :class="{'library': !discovery}"
      :to="{ name: discovery ? 'DiscoveryBrandView' : 'BrandView', params: { id: brand.id } }">
        <!-- Brand name & avatar -->
        <div class="flex items-center gap-3 p-2">
          <img :src="brand.avatar" class="w-7 h-7 rounded-full" />
          <BaseText type="label" size="sm" class="text-text-muted truncate">
            {{ brand.name }}
          </BaseText>
        </div>
        <!-- Brand platform -->
        <div class="px-3 py-2.5">
          <div v-if="brand?.publisherPlatforms?.length || brand.adLibraryId" class="flex items-center gap-2">
            <component :is="getPlatformIcon(brand.publisherPlatforms?.[0] ?? (brand.adLibraryId ? 'facebook' : ''))" :width="16" :height="16" />
            <BaseText type="body" size="sm" class="text-text-normal capitalize py-0.5">
              {{ formatPlatformName(brand.publisherPlatforms?.[0]) ?? (brand.adLibraryId ? 'Facebook' : '') }}
            </BaseText>
            <!-- Additional platforms indicator -->
            <div v-if="brand?.publisherPlatforms?.length > 1" class="relative px-1 py-0.5 border border-border-normal bg-white
            transition-colors hover:bg-neutral-50 hover:border-transparent" style="border-radius: 4px"
            @mouseenter="hoveredPlatformTooltip = brand.id" @mouseleave="hoveredPlatformTooltip = null">
              <BaseText type="label" size="xs" class="text-text-normal">
                +{{ brand.publisherPlatforms.length - 1 }}
              </BaseText>
              <!-- Rendered tooltip on hover -->
              <transition>
                <div v-if="hoveredPlatformTooltip === brand.id" class="pb-1 platform-tooltip">
                  <div class="flex flex-col gap-1 px-1 py-1.5 rounded-xl bg-neutral-malpha-25"
                  style="width: 180px;" @mouseenter="hoveredPlatformTooltip = null">
                    <div v-for="(platform, index) in brand.publisherPlatforms.slice(1)" 
                    :key="`brand-platform-${index}`" class="w-full flex items-center gap-2 px-1.5 py-1">
                      <div class="p-0.5">
                        <component :is="getPlatformIcon(platform)" :width="16" :height="16" />
                      </div>
                      <BaseText type="body" size="sm" class="text-white capitalize">
                        {{ formatPlatformName(platform) }}
                      </BaseText>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <!-- Brand industry (niche) - TODO: Probably needs to be cleaned up -->
        <div class="p-3">
          <BaseText v-if="brand?.niches?.length || brand.category" type="body" size="sm" class="text-text-normal capitalize truncate">
            {{ brand?.niches?.length ? brand.niches[0] : brand.category.split(' ').slice(0, 1)[0] }}
          </BaseText>
        </div>
        <!-- Number of ads saved -->
        <!-- TODO: In SwipeFile this should show the number of that brand's ads the user has saved -->
        <div v-if="discovery" class="p-3">
          <BaseText type="body" size="sm" class="text-text-normal">
            {{ brand.adsSaved.toLocaleString() }} Ads
          </BaseText>
        </div>
        <!-- Buttons/Actions -->
        <div class="flex items-center justify-end px-3 py-1.5">
          <button class="group w-8 h-8 rounded-md transition-colors hover:bg-neutral-50"
          @click.stop.prevent="$emit('favorite', brand.brandId || brand.id)">
            <FavoriteIcon no-hover :active="favoritedBrands.includes(brand.brandId || brand.id)"
            class="text-icon-normal transition-colors group-hover:text-icon-muted" />
          </button>
          <div class="w-px h-4 mx-2 bg-border-normal" />
          <button class="group flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md"
          :class="brand.adLibraryId || brand.linkedinId ? 'transition-colors hover:bg-neutral-50' : 'cursor-default opacity-30'"
          @click.stop.prevent="() => {goToAdLibrary(brand)}" :disabled="!brand.adLibraryId && !brand.linkedinId">
            <AdLibraryIcon class="text-icon-normal" :class="{'transition-colors group-hover:text-icon-muted': brand.adLibraryId || brand.linkedinId}" />
            <BaseText type="label" size="sm" class="text-text-muted">
              Ad Library
            </BaseText>
          </button>
          <div class="w-px h-4 mx-2 bg-border-normal" />
          <button class="group flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md"
          :class="brand.adLibraryId ? 'transition-colors hover:bg-neutral-50' : 'cursor-default opacity-30'"
          @click.stop.prevent="() => {trackSpyderBrand(brand)}" :disabled="!brand.adLibraryId">
            <AnalyzeSpyderIcon class="text-icon-normal" :class="{'transition-colors group-hover:text-icon-muted': brand.adLibraryId}" />
            <BaseText type="label" size="sm" class="text-text-muted">
              Analyze in Spyder
            </BaseText>
          </button>
        </div>
      </router-link>
    </div>
    <!-- Infinite loading -->
    <infinite-loading
      v-if="brands.length"
      :identifier="infiniteId"
      @infinite="$emit('get-more-brands', $event)"
    >
      <div slot="spinner">
        <BaseLoadingLogo :margin="2" />
      </div>
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

// Icons
import FavoriteIcon from '../globals/Icons/FavoriteIcon.vue'
import AdLibraryIcon from '../globals/Icons/AdLibraryIcon.vue'
import AnalyzeSpyderIcon from '../globals/Icons/AnalyzeSpyderIcon.vue'
import NoBrandFavoritesAnimatedIcon from '../globals/Icons/NoBrandFavoritesAnimatedIcon.vue'
import NoBrandsAnimatedIcon from '../globals/Icons/NoBrandsAnimatedIcon.vue'
import * as Icons from '../globals/Icons/FilterIcons'

export default {
  name: 'BrandsList',
  components: {
    FavoriteIcon,
    AdLibraryIcon,
    AnalyzeSpyderIcon,
    NoBrandFavoritesAnimatedIcon,
    NoBrandsAnimatedIcon,
    InfiniteLoading
  },
  props: {
    brands: {
      type: Array,
      required: true
    },
    brandTotal: {
      type: String,
      default: ''
    },
    loadingBrands: {
      type: Boolean,
      default: false
    },
    favoriteFilter: {
      type: Boolean,
      default: false
    },
    favoritedBrands: {
      type: Array,
      default: () => []
    },
    infiniteId: {
      type: Number,
      default: 0
    },
    discovery: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hoveredPlatformTooltip: null,
    }
  },
  methods: {
    goToAdLibrary (brand) {
      if (!brand.adLibraryId && !brand.linkedinId) return
      const url = brand.adLibraryId 
        ? `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${brand.adLibraryId}`
        : `https://www.linkedin.com/company/${brand.linkedinId}/posts/?feedView=ads`
      window.open(url, '_blank')      
    },
    trackSpyderBrand (brand) {
      if (!brand.adLibraryId) return
      this.$router.push({ name: 'SpyderBrandsView', query: { id: brand.adLibraryId } })
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    formatPlatformName (platform) {
      switch (platform) {
        case 'tiktok': return 'TikTok'
        case 'youtube': return 'YouTube'
        case 'linkedin': return 'LinkedIn'
        default: return platform
      }
    },
    getPlatformIcon (platform) {
      switch (platform) {
        case 'facebook': return Icons.FacebookPlatformIcon
        case 'instagram': return Icons.InstagramPlatformIcon
        case 'tiktok': return Icons.TikTokPlatformIcon
        case 'youtube': return Icons.YouTubePlatformIcon
        case 'linkedin': return Icons.LinkedInPlatformIcon
        case 'messenger': return Icons.MessengerPlatformIcon
        default: return Icons.OtherNicheIcon
      }
    }
  }
}
</script>

<style scoped>
.platform-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 40001; /* So it renders above filter top bar */
}

/* Grid rendering classes with media queries - columns get removed at smaller widths */
/* Order of removal: Platform -> Industry -> # Community Ads */
.brand-grid-template {
  display: grid;
  grid-template-columns: 1fr 156px 156px 144px 396px;
}
/* We have different rules for the responsive grid in SwipeFile (since ad count is hidden) */
.brand-grid-template.library {
  display: grid;
  grid-template-columns: 1fr 156px 156px 396px;
}
@media (max-width: 1480px) {
  .brand-grid-template > :nth-child(2) {
    display: none;
  }
  .brand-grid-template {
    grid-template-columns: 1fr 156px 144px 396px;
  }
  .brand-grid-template.library {
    grid-template-columns: 1fr 156px 396px;
  }
}
@media (max-width: 1325px) {
  .brand-grid-template > :nth-child(3) {
    display: none;
  }
  .brand-grid-template {
    grid-template-columns: 1fr 144px 396px;
  }
  .brand-grid-template.library {
    grid-template-columns: 1fr 396px;
  }
}
@media (max-width: 1175px) {
  .brand-grid-template > :nth-child(4) {
    display: none;
  }
  .brand-grid-template.library > :nth-child(4) {
    display: inline-flex;
  }
  .brand-grid-template {
    grid-template-columns: 1fr 396px;
  }
}

/* Vue <transition> classes */
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>